import React, { useEffect, useState } from "react";
import useTranslate from "hooks/use-translate-language";
import { formatPhone, normalizePhone } from "utils/misc";
import { isValidPhoneNumber } from "utils/validation";
import { useFormik } from "formik";
import { Checkbox, TextField } from "@mui/material";
import { LoadingButton, Spinner } from "components/ui";
import * as Yup from "yup";

export default function MultifactorOptionsForms({
  patientInfo,
  setPhoneNumberApi,
  verifyPhoneNumberApi
}) {
  const translate = useTranslate();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [useMFAPreviousValue, setUseMFAPreviousValue] = useState(
    patientInfo.mfa_enabled || false
  );

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const fkUseMFA = useFormik({
    initialValues: {
      use_multifactor: patientInfo.mfa_enabled || false
    },
    onSubmit: async ({ use_multifactor }) => {
      if (!use_multifactor) {
        fkTextVerify.setFieldValue("use_text_verify", false);
        fkPhoneNumber.setFieldValue("phone_number", undefined);
        fkVerificationCode.setFieldValue("verification_code", undefined);
      }
      await setPhoneNumberApi(
        use_multifactor,
        use_multifactor
          ? fkPhoneNumber.values.phone_number || undefined
          : undefined
      );
    }
  });

  const fkTextVerify = useFormik({
    initialValues: {
      use_text_verify: patientInfo.mfa_phone_number_verified || false
    },
    onSubmit: async () => {
      await setPhoneNumberApi(fkUseMFA.values.use_multifactor, undefined);
    }
  });

  const fkPhoneNumber = useFormik({
    initialValues: {
      phone_number: patientInfo.mfa_phone_number || ""
    },
    validationSchema: Yup.object().shape({
      phone_number: Yup.string()
        .required(translate("required"))
        .test("valid-number", translate("invalidPhoneNumber"), val =>
          isValidPhoneNumber(val)
        )
    }),
    onSubmit: async ({ phone_number }) => {
      await setPhoneNumberApi(true, phone_number);
    }
  });

  const fkVerificationCode = useFormik({
    initialValues: {
      verification_code: ""
    },
    onSubmit: async ({ verification_code }) => {
      await verifyPhoneNumberApi(verification_code);
    }
  });

  useEffect(() => {
    const value = fkUseMFA.values.use_multifactor;
    if (value !== useMFAPreviousValue) {
      setUseMFAPreviousValue(value);
      fkUseMFA.submitForm();
    }
  }, [fkUseMFA.values.use_multifactor]);

  useEffect(() => {
    if (!isFirstRender && fkUseMFA.values.use_multifactor) {
      const value = fkTextVerify.values.use_text_verify;
      if (!value) {
        fkPhoneNumber.setFieldValue("phone_number", undefined);
        fkVerificationCode.setFieldValue("verification_code", undefined);
        fkTextVerify.submitForm();
      }
    }
  }, [fkTextVerify.values.use_text_verify]);

  const getFieldError = (field, errors, touched) => {
    return touched[field] ? errors[field] : "";
  };

  const handleInputChange = (event, setFieldValue) => {
    let { name, value } = event.target;
    if (name === "phone_number") {
      value = normalizePhone(value);
    }
    setFieldValue(name, value);
  };

  return (
    <div className="profile-panel">
      <form className="use-multifactor-form">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 className="profile-panel-header">
            {translate("useMultifactor")}
          </h4>

          {fkUseMFA.isSubmitting ? (
            <Spinner
              style={{
                marginLeft: "1rem"
              }}
              size="1rem"
            />
          ) : (
            <Checkbox
              name="use_multifactor"
              checked={fkUseMFA.values.use_multifactor}
              color="primary"
              onChange={e => fkUseMFA.handleChange(e)}
            />
          )}
        </div>
      </form>
      {/*fkUseMFA.values.use_multifactor && !fkUseMFA.isSubmitting && (
        <div className="profile-content">
          <div>
            <form style={{ display: "flex", alignItems: "center" }}>
              <p>{translate("useVerficiationByText")}</p>
              {fkTextVerify.isSubmitting ? (
                <Spinner
                  style={{
                    marginLeft: "1rem"
                  }}
                  size="1rem"
                />
              ) : (
                <Checkbox
                  name="use_text_verify"
                  checked={fkTextVerify.values.use_text_verify}
                  color="primary"
                  onChange={e => fkTextVerify.handleChange(e)}
                />
              )}
            </form>

            {fkTextVerify.values.use_text_verify &&
              !fkTextVerify.isSubmitting && (
                <>
                  <form
                    className="phone-number-form"
                    onSubmit={fkPhoneNumber.handleSubmit}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline"
                      }}
                    >
                      <TextField
                        id="phone_number"
                        name="phone_number"
                        label={translate("verifyPhoneNumber")}
                        type="tel"
                        onBlur={fkPhoneNumber.handleBlur}
                        value={
                          formatPhone(fkPhoneNumber.values.phone_number) || ""
                        }
                        onChange={e => {
                          return handleInputChange(
                            e,
                            fkPhoneNumber.setFieldValue
                          );
                        }}
                        error={
                          !!getFieldError(
                            "phone_number",
                            fkPhoneNumber.errors,
                            fkPhoneNumber.touched
                          )
                        }
                        helperText={getFieldError(
                          "phone_number",
                          fkPhoneNumber.errors,
                          fkPhoneNumber.touched
                        )}
                      />
                      <LoadingButton
                        color="primary"
                        type="submit"
                        isFetching={fkPhoneNumber.isSubmitting}
                        disabled={
                          !fkPhoneNumber.isValid ||
                          !fkPhoneNumber.values.phone_number
                        }
                      >
                        {translate("sendCode")}
                      </LoadingButton>
                    </div>
                  </form>

                  <form
                    className="verification-code-form"
                    onSubmit={fkVerificationCode.handleSubmit}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: "15px"
                      }}
                    >
                      <TextField
                        id="verification_code"
                        name="verification_code"
                        type="text"
                        value={
                          fkVerificationCode.values.verification_code || ""
                        }
                        onChange={e => {
                          return handleInputChange(
                            e,
                            fkVerificationCode.setFieldValue
                          );
                        }}
                        error={
                          !!getFieldError(
                            "verification_code",
                            fkVerificationCode.errors,
                            fkVerificationCode.touched
                          )
                        }
                        helperText={getFieldError(
                          "verification_code",
                          fkVerificationCode.errors,
                          fkVerificationCode.touched
                        )}
                      />
                      <LoadingButton
                        color="primary"
                        type="submit"
                        isFetching={fkVerificationCode.isSubmitting}
                        disabled={
                          !fkVerificationCode.values.verification_code ||
                          fkVerificationCode.values.verification_code.length !==
                            6
                        }
                      >
                        {translate("verify")}
                      </LoadingButton>
                    </div>
                  </form>
                </>
              )}
          </div>
        </div>
      )*/}
    </div>
  );
}
