export default {
  // index
  myInfo: {
    english: "My Info",
    spanish: "Mi información",
  },
  insurance: {
    english: "Insurance",
    spanish: "Seguro",
  },
  physician: {
    english: "Physician",
    spanish: "Médico",
  },
  multifactorAuthentication: {
    english: "Multifactor Authentication",
    spanish: "Autenticación por Factor Multiple"
  },
  // orderSupplies  is listed in dashboard
  // info
  email: {
    english: "Email",
    spanish: "Correo electrónico",
  },
  primaryPhoneNumber: {
    english: "Primary Phone Number",
    spanish: "Número de teléfono primario",
  },
  secondaryPhoneNumber: {
    english: "Secondary Phone Number",
    spanish: "Número de teléfono secundario",
  },
  address: {
    english: "Address",
    spanish: "Dirección",
  },
  // eligibleForSupplies listed in order-form
  primaryLanguage: {
    english: "Primary Language",
    spanish: "Lenguaje primario",
  },
  englishOrSpanish: { english: "English", spanish: "Español" },
  //  insurance
  insurances: {
    english: "Insurances",
    spanish: "Seguros",
  },
  primaryInsurance: {
    english: "Primary Insurance",
    spanish: "Seguro primario",
  },
  secondaryInsurance: {
    english: "Secondary Insurance",
    spanish: "Seguro secundario",
  },
  //   physician

  name: {
    english: "Name",
    spanish: "Nombre",
  },
  phone: {
    english: "Phone",
    spanish: "Teléfono",
  },
  fax: {
    english: "Fax",
    spanish: "Fax",
  },
  notes: {
    english: "Notes",
    spanish: "Notas",
  },
  editProfile: {
    english: "Edit Profile",
    spanish: "Editar perfil",
  },
  //   notification-settings-modal
  typeOfNotifications: {
    english: "What type of notifications would you like to receive?",
    spanish: "¿Qué tipo de notificaciones le gustaría recibir?",
  },
  notificationSettings: {
    english: "Notification Settings",
    spanish: "Configuración de las notificaciones",
  },
  options: {
    english: "Options",
    spanish: "Opciones",
  },
  pushNotifications: {
    english: "Push Notifications",
    spanish: "Notificaciones push",
  },
  smsMessages: {
    english: "SMS Messages",
    spanish: "Mensajes SMS",
  },
  videoChats: {
    english: "Video Chats",
    spanish: "Chats de video",
  },
  textChats: {
    english: "Text Chats",
    spanish: "Chats de texto",
  },

  fileUploads: {
    english: "File Uploads",
    spanish: "Subidas de archivos",
  },
  profileUpdates: {
    english: "Profile Updates",
    spanish: "Actualizaciones de perfil",
  },
  saveChanges: {
    english: "Save Changes",
    spanish: "Guardar cambios",
  },
  // questionnaires listed in dashboard
  // Change password Modal
  changePassword: {
    english: "Change Password",
    spanish: "Cambiar la contraseña",
  },
  resetPassword: {
    english: "Reset Password",
    spanish: "Restablecer la contraseña",
  },
  currentPassword: {
    english: "Current Password",
    spanish: "Contraseña actual",
  },
  newPassword: {
    english: "New Password",
    spanish: "Nueva contraseña",
  },
  newPasswordConfirmation: {
    english: "New Password Confirmation",
    spanish: "Nueva confirmación de contraseña",
  },
  submit: {
    english: "Submit",
    spanish: "Enviar",
  },
  // multifactor options
  useMultifactor: {
    english: "Use Multifactor Authentication",
    spanish: "Usar Autenticación por Factor Multiple"
  },
  useVerficiationByText: {
    english: "Use verification code by text message",
    spanish: "Usar código de verificación por mensaje de texto"
  },
  verifyPhoneNumber: {
    english: "Verify phone number",
    spanish: "Verificar número de teléfono"
  },
  sendCode: {
    english: "Send Code",
    spanish: "Enviar Código"
  },
  verify: { english: "Verify", spanish: "Verificar" },
  required: {
    english: "Required",
    spanish: "Requerido"
  },
  invalidPhoneNumber: {
    english: "Invalid Phone Number",
    spanish: "Número de teléfono inválido"
  },
  incorrectCode: {
    english: "Incorrect code, please try again",
    spanish: "Código incorrecto, por favor intenta de nuevo"
  },
  MFAUpdated: {
    english: "Multifactor Authentication Updated Successfully",
    spanish: "Autenticación por Factor Multiple fue actualizada exitosamente"
  },
  verificationCodeSent: {
    english: "Verification code sent",
    spanish: "Código de verificación enviado"
  },
  phoneVerificationSucceded: {
    english: "Phone Number Verification Succeded",
    spanish: "Verificación del número de teléfono exitosa"
  }
};
